export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const SET_LOADING = "SET_LOADING";
export const SET_STATUS = "SET_STATUS";
export const SET_REG_USER = "SET_REG_USER";

export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const IS_LOG_IN = "IS_LOG_IN";

export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER = "SET_USER";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_BANK_STATS = "SET_BANK_STATS";
export const SET_KYC_TOKEN = "SET_KYC_TOKEN";
export const SET_KYC_ID = "SET_KYC_ID";
export const SET_RECOMMENDS = "SET_RECOMMENDS";
export const ADD_RECOMMENDS = "ADD_RECOMMENDS";
export const SET_COG_TRANSACTIONS = "SET_COG_TRANSACTIONS";
export const SET_SPONSOR_LIST = "SET_SPONSOR_LIST";
export const SET_OVER_DRAFTS = "SET_OVER_DRAFTS";
export const SET_ACTIVE_OVERDRAFTS = "SET_ACTIVE_OVERDRAFTS";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const SET_BUSINESSES = "SET_BUSINESSES";
export const SET_FILLEDDATA = "SET_FILLEDDATA";
export const SET_TRADERS = "SET_TRADERS";
export const SET_TRADER = "SET_TRADER";
export const SET_ASSETS = "SET_ASSETS";
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";
export const SET_ESCROW_ACCOUNT = "SET_ESCROW_ACCOUNT";
export const SET_ALL_ASSETS = "SET_ALL_ASSETS";
export const SET_ESCROW_WINDOW = "SET_ESCROW_WINDOW";
export const SET_USER_STATE = "SET_USER_STATE";
export const SET_USER_URI_GROUP_ID = "SET_USER_URI_GROUP_ID";
export const SET_USER_URI_DETAIL = "SET_USER_URI_DETAIL";
export const SET_QUIZ_QUESTION = "SET_QUIZ_QUESTION";
export const UPDATE_USER_REDUCER = "UPDATE_USER_REDUCER";
export const SET_BANK_ACCOUNTS = "SET_BANK_ACCOUNTS";
export const SET_CBC_TRANSFERS = "SET_CBC_TRANSFERS";
export const SET_STOCK_LIST = "SET_STOCK_LIST";
export const SET_USER_STOCKS = "SET_USER_STOCKS";
export const SET_STOCK_TRANSACTIONS = "SET_STOCK_TRANSACTIONS";
export const SET_CRYPTOPIA_DATA = "SET_CRYPTOPIA_DATA";
export const SEND_NEW_AGREEMENT = "SEND_NEW_AGREEMENT";
export const SET_USER_KYC = "SET_USER_KYC";
export const SET_STOCK_ENGINE_LIST = "SET_STOCK_ENGINE_LIST";
export const SET_STOCK_WALLET_DATA = "SET_STOCK_WALLET_DATA";
export const SET_STOCK_TRANSACTION_DATA = "SSET_STOCK_TRANSACTION_DATA";
export const SET_STOCK_WALLET_TRANSACTION_DATA =
  "SET_STOCK_WALLET_TRANSACTION_DATA";
export const SET_STOCK_PRICE_DATA = "SET_STOCK_PRICE_DATA";
export const SET_ORDER_BOOK = "SET_ORDER_BOOK";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_STOCK_REALTIME = "UPDATE_STOCK_REALTIME";
export const GET_ORDER_BOOK_REAL_TIME = "GET_ORDER_BOOK_REAL_TIME";
export const SET_CURRENT_ID = "SET_CURRENT_ID";
export const SET_SPECIFIC_STOCK_PRICE_ARR = "SET_SPECIFIC_STOCK_PRICE_ARR";
export const SET_STOCK_CATEGORY_DATA = "SET_STOCK_CATEGORY_DATA";
export const SET_CURRENT_STOCK_CATEGORY = "SET_CURRENT_STOCK_CATEGORY";
export const SET_COG_VALUE_STOCK = "SET_COG_VALUE_STOCK";
export const SET_USER_PREVIOUS_STOCKS = "SET_USER_PREVIOUS_STOCKS";
export const SET_STOCKS_DETAILS_TEMPLATE = "SET_STOCKS_DETAILS_TEMPLATE";
export const SET_INVESTMENT_DETAILS = "SET_INVESTMENT_DETAILS";
export const SET_STOCK_TRANSACTION_EXPORT_DATA =
  "SSET_STOCK_TRANSACTION_EXPORT_DATA";
  export const SET_WALLET_TRANSACTION_EXPORT_DATA =
  "SSET_WALLET_TRANSACTION_EXPORT_DATA";
export const SET_CRYPTOPIA_DATA_AVAILABLE = "SET_CRYPTOPIA_DATA_AVAILABLE";
