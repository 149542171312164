import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  Modal,
  Tabs,
  Tab,
  TextField,
  withStyles,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  Backdrop,
  IconButton,
  Paper,
} from "@material-ui/core";
import {
  Add,
  AddCircleOutline,
  AddCircleOutlined,
  CancelOutlined,
  Remove,
} from "@material-ui/icons";
import OTPInput from "react-otp-input";
import { useTheme } from "@material-ui/core/styles";
import { PieChart } from "react-minimal-pie-chart";
import Donut from "react-donut";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actionCreator from "../../../store/action/index";
import Spinner from "../../common/Spinner/Spinner";
import Smallspinner from "../../common/smallSpinner/smallSpinner";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NumberWithTooltip from "../../../utils/numberFormater/NumberWithTooltip";

const useStyles = makeStyles((theme) => ({
  cardBox: {
    padding: "10px 30px",
    paddingBottom: "10rem",
    margin: "2% 0%",
    // width: "100%",
    boxShadow: localStorage?.getItem("dark")
      ? "-4px -4px 10px rgba(58, 58, 58, 0.7)"
      : "none",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
  textGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",

    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
  },
  textGrid1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",

    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
  },

  subText: {
    display: "flex",
    color: "#ACACAC",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
  },
  price: {
    color: localStorage?.getItem("dark") ? "#359DB6" : "#C9E5EC",
    fontSize: "1.8rem",
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  walletMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  walletLeftMain: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  walletRightMain: {
    width: "50%",
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: "7%",
    },
  },
  tabContainerSTyle: {
    border: "1px solid #848484",
    borderRadius: "10px",
    marginTop: "1.5rem",
  },
  tabsStyle: {
    display: "flex",
    gap: "20px",
    borderBottom: "1px solid #848484",
    padding: "15px",
  },
  selectedTab: {
    color: "#FFB476",
    borderBottom: "1px solid #FFB476",
  },
  DepositSectionFirst: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    gap: "30px",
  },

  BuyButton: {
    backgroundColor: "#359DB6",
    textAlign: "center",
    margin: "6px 0px",
    borderRadius: "10px",
    width: "25%",
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "auto",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  icon: {
    fill: "#FFB476",
  },
  modalContainer: {
    // height: "60vh",
    // width: "50%",
    // right: "10%",
    marginTop: "8rem",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    boxShadow: "",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      right: "0",
    },
    boxShadow: localStorage.getItem("dark")
      ? "-2px -2px 2px 0px rgba(255,255,255,1)"
      : " -2px -2px 5px 0px rgba(0,0,0,0.75)",
  },
  modalHeading: {
    display: "flex",
    backgroundColor: localStorage.getItem("dark")
      ? "#cdcdcd"
      : "rgb(31, 31, 31)",
    height: "10vh",
    justifyContent: "space-between",
    alignItems: "center",
  },
  otpBox: {
    width: "35px !important",
    height: "35px !important",

    [theme.breakpoints.down("md")]: {
      width: "30px !important",
      height: "30px !important",
    },
  },
  PayButton: {
    marginTop: "1rem",
    width: "80%",
    borderRadius: "50px",
    backgroundColor: "#359DB6",
    alignSelf: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  errorTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-error fieldset": {
        borderColor: "red",
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    fontSize: "0.6rem",
    "& label.Mui-focused": {
      color: "#ff7300",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ff7300",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid rgba(0, 123, 255, .25)",
      },
      "&:hover fieldset": {
        border: "2px solid rgba(0, 123, 255, .25)",
      },
      "&.Mui-focused fieldset": {
        borderRadius: "10px",
        border: "1px solid #ff7300",
      },
    },
    "& .Mui-error .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderWidth: "2px",
      },
    },
  },
})(TextField);

const GradientTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "& input": {
        background: "linear-gradient(to right, #ffcccc 60%, #ccffcc 60%)",
      },
    },
  },
})(TextField);

const MenuProps = (theme) => ({
  PaperProps: {
    style: {
      backgroundColor: theme.palette.background.default,
      fontSize: "12px",
      width: "auto",
      // Add other styles or adjustments based on theme.palette or other theme values
    },
  },
});
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: "10px",
    fontSize: 12,

    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
  },
}))(InputBase);

const Wallet = (props) => {
  const {
    walletData,
    walletDataLoading,
    depositEngineWallet,
    withdrawEngineWallet,
    getEngineWallet,
    notify,
    depositWithdrawLoading,
    profile,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [currencyType, setCurrencyType] = useState("COG100");
  const [depositAmount, setDepositAmount] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [walletModalOpen, setWalletModalOpen] = useState(null);
  const [security, setSecurity] = useState("");
  const [error, setError] = useState("");
  const [amounts, setAmounts] = useState({ cbc: "", cog: "" });
  const [helperText, setHelperText] = useState(null);
  const [amnt, setAmnt] = useState(null);

  useEffect(() => {
    if (withdrawAmount > amnt) {
      setHelperText(
        `Withdraw amount cannot be greater than ${currencyType} total amount `
      );
    } else {
      setHelperText(null);
    }
  }, [amnt, withdrawAmount]);

  useEffect(() => {
    setDepositAmount("");
    setWithdrawAmount("");
    setAmounts({ cbc: "", cog: "" });
    setCurrencyType("COG100");
  }, [selectedTab]);

  const currencyLabels = {
    COG100: "COG 100%",
    CB25CO75: "Cog 75%,CBC 25%",
    CB50CO50: "Cog 50%,CBC 50%",
    CB75CO25: "Cog 25%,CBC 75%",
    CBC100: "CBC 100%",
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const tabs = ["Deposit", "Withdraw"];

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
    setHelperText(null);
  };

  const getBackgroundGradient = (currType) => {
    if (currType === currencyType) {
      return {
        background: "linear-gradient(to right, #1B5563 65%, #359DB6 35%)",
        color: "#fff",
      };
    } else {
      return {
        background: "linear-gradient(to right, #494949 65%, #2c2c2c 35%)",
        color: "#fff",
      };
    }
  };

  const cogValues = [
    { title: "Cog 100%", name: "COG100", color: "#90CAF9" },
    {
      title: "Cog 75% CBC 25%",
      name: "CB25CO75",
      color: "#FFB476",
    },
    {
      title: "Cog 50% CBC 50%",
      name: "CB50CO50",
      color: "#D3766A",
    },
    {
      title: "Cog 25% CBC 75%",
      name: "CB75CO25",
      color: "#FF9D9C",
    },
    { title: "CBC 100%", name: "CBC100", color: "#C49C39" },
  ];

  const totalValue = walletData.reduce(
    (total, wallet) => total + wallet.amount,
    0
  );

  const getAmountForWallet = (walletName) => {
    const walletEntry = walletData.find(
      (wallet) => wallet.walletName === walletName
    );
    if (walletEntry) {
      return walletEntry.amount;
    } else {
      return 0;
    }
  };

  const walletDataWithPercentage = walletData.map((wallet) => {
    const cogValueEntry = cogValues.find(
      (cog) => cog.name === wallet.walletName
    );

    return {
      ...wallet,
      percentage: cogValueEntry ? (wallet.amount / totalValue) * 100 : 0,
      color: cogValueEntry.color,
      title: cogValueEntry.title,
    };
  });

  const handleCurrencyChange = (event) => {
    const amount = getAmountForWallet(event.target.value);
    setAmnt(amount);
    setCurrencyType(event.target.value);
    distributeCurrencyType(
      selectedTab == "0" ? depositAmount : withdrawAmount,
      event.target.value
    );
  };

  const handleWalletModal = () => {
    setWalletModalOpen(true);
  };

  const handleCloseModal = () => {
    setWalletModalOpen(false);
    setSecurity("");
  };

  const distributeCurrencyType = (val, currencyType) => {
    const amt = parseFloat(val);
    if (isNaN(amt)) {
      setAmounts({ cbc: 0, cog: 0 });
      return;
    }
    let cbc = 0;
    let cog = 0;

    switch (currencyType) {
      case "COG100":
        cog = amt;
        break;
      case "CB25CO75":
        cbc = amt * 0.25;
        cog = amt * 0.75;
        break;
      case "CB50CO50":
        cbc = amt * 0.5;
        cog = amt * 0.5;
        break;
      case "CB75CO25":
        cbc = amt * 0.75;
        cog = amt * 0.25;
        break;
      case "CBC100":
        cbc = amt;
        break;
      default:
        break;
    }

    setAmounts({ cbc, cog });
  };

  const depositHandler = (e) => {
    setDepositAmount(e.target.value);
    distributeCurrencyType(e.target.value, currencyType);
  };

  const withdrawHandler = (e) => {
    const amount = getAmountForWallet(currencyType);
    setAmnt(amount);
    setWithdrawAmount(e.target.value);

    distributeCurrencyType(e.target.value, currencyType);
  };

  const stockHandler = () => {
    if (security === "" || security?.length < 6) {
      setError("Please enter 6 digit PIN");
    } else if (selectedTab == "0") {
      let reqBody = {
        amount: parseInt(depositAmount),
        pin: parseInt(security),
        assetInfo: currencyType,
      };
      depositEngineWallet(reqBody)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            notify(successMessage || "Deposit Successfully", "success");
            handleCloseModal();
            setDepositAmount("");
            setAmounts({ cbc: "", cog: "" });
            setCurrencyType("COG100");
            getEngineWallet();
          } else {
            notify(error || errorMessage, "error");
            handleCloseModal();
            setDepositAmount("");
            setAmounts({ cbc: "", cog: "" });
            setCurrencyType("COG100");
          }
        })
        .catch((err) => {
          notify(err?.error, "error");
          handleCloseModal();
          setDepositAmount("");
          setAmounts({ cbc: "", cog: "" });
          setCurrencyType("COG100");
        });
    } else {
      let reqBody = {
        amount: withdrawAmount,
        pin: parseInt(security),
        assetInfo: currencyType,
      };
      withdrawEngineWallet(reqBody)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            notify(successMessage || "Withdraw Successfully", "success");
            handleCloseModal();
            setWithdrawAmount("");
            setCurrencyType("COG100");
            getEngineWallet();
          } else {
            notify(error || errorMessage, "error");
            handleCloseModal();
            setWithdrawAmount("");
            setCurrencyType("COG100");
          }
        })
        .catch((err) => {
          notify(err?.error, "error");
          handleCloseModal();
          setWithdrawAmount("");
          setCurrencyType("COG100");
        });
    }
  };

  const depositAmountNumeric = parseFloat(depositAmount);
  const withdrawAmountNumeric = parseFloat(withdrawAmount);

  const depositTransferTax = depositAmountNumeric
    ? (depositAmountNumeric * 0.5) / 100
    : 0;

  const withdrawTransferTax = withdrawAmountNumeric
    ? (withdrawAmountNumeric * 0.5) / 100
    : 0;

  const totalTransactionDepositAmount =
    depositAmountNumeric + depositTransferTax;

  const totalTransactionWithdrawAmount =
    withdrawAmountNumeric + withdrawTransferTax;

  const data = walletDataWithPercentage
    // .filter((item) => item.percentage > 0)
    .map((item) => ({
      name: item.walletName,
      data: item.percentage.toFixed(2),
      color: item.color,
    }));



  const adjustedData = data.map((item) => ({
    ...item,
    data: item.data === null || isNaN(parseFloat(item.data)) ? 0.001 : parseFloat(item.data),
  }));
  

  
  

  return (
    <>
      {walletDataLoading ? (
        <Spinner />
      ) : (
        <Grid item container xs={12} className={classes.cardBox}>
          <Grid item xs={12} className={classes.walletMain}>
            <Grid item xs={12} sm={6} className={classes.walletLeftMain}>
              <Typography
                style={{
                  color: "#359DB6",
                  fontSize: "24px",
                }}
              >
                <NumberWithTooltip value={totalValue} position="wallet" />
                &nbsp;&nbsp; Total Balance
              </Typography>

              {cogValues.map((item, index) => {
                const cogColor = cogValues[index].color;
                const amount = getAmountForWallet(item.name);

                return (
                  <div
                    key={item.name}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderLeft: `25px solid ${cogColor}`,
                      background: getBackgroundGradient(item.name).background,
                      color: getBackgroundGradient(item.name).color,
                      borderRadius: 10,
                      marginTop: "1rem",
                      padding: "0.5rem",
                    }}
                  >
                    <div style={{ width: "10%" }}></div>
                    <div style={{ width: "60%" }}>{item.title}</div>
                    <div style={{ width: "30%", textAlign: "center" }}>
                      <NumberWithTooltip value={amount} />
                    </div>
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.walletRightMain}>
              {adjustedData && adjustedData?.length > 0 ? (
                <Donut
                  chartData={adjustedData}
                  chartWidth={500}
                  chartHeight={450}
                  chartThemeConfig={{
                    chart: {
                      background: "transparent",
                    },
                    title: {
                      color: "transparent",
                    },
                    chartExportMenu: {
                      backgroundColor: "transparent",
                      color: "transparent",
                    },
                    legend: {
                      label: {
                        color: `#359DB6`,
                        fontSize: 14,
                        fontFamily: "Arial",
                        fontWeight: "bold",
                      },
                    },
                    series: {
                      colors: adjustedData.map((item) => item.color),
                      label: {
                        color: "#000",
                        fontSize: 14,
                        fontFamily: "Arial",
                        fontWeight: "bold",
                      },
                    },
                  }}
                />
              ) : (
                null
              )}

              {/* </div> */}
            </Grid>
          </Grid>

          <Grid item xs={10} className={classes.tabContainerSTyle}>
            <Grid item xs={12} className={classes.tabsStyle}>
              <Tabs
                value={selectedTab}
                onChange={(e, v) => handleTabChange(v)}
                variant="fullWidth"
                TabIndicatorProps={{
                  style: {
                    background: "#FF7300",
                    height: "7px",
                    borderRadius: "2px",
                  },
                }}
                classes={{
                  root: classes.customTabRoot,
                  indicator: classes.customTabIndicator,
                }}
                aria-label="tabs"
              >
                {tabs?.map((tab, index) => {
                  return (
                    <Tab
                      key={index}
                      label={<span>{tab}</span>}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
            </Grid>

            {selectedTab == 0 ? (
              <Grid item className={classes.DepositSectionFirst}>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  className={classes.textGrid}
                >
                  {/* <Grid item container xs={12} sm={5} > */}
                  <Grid item xs={5}>
                    <Typography style={{ textAlign: "center" }}>
                      Amount
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      value={depositAmount}
                      onChange={(e) => depositHandler(e)}
                      style={{
                        borderRadius: 10,
                        outline: "none",
                      }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography style={{ textAlign: "center" }}>
                      Wallet Currency Option
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      value={currencyLabels[currencyType] || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Select
                              value=""
                              onChange={handleCurrencyChange}
                              input={<BootstrapInput />}
                              IconComponent={ArrowDropDownIcon}
                              MenuProps={MenuProps(theme)}
                              id="paymentTypeDropdown"
                              classes={{ icon: classes.icon }}
                            >
                              <MenuItem
                                value="COG100"
                                id="cog100"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "COG100"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "COG100"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                COG 100%
                              </MenuItem>
                              <MenuItem
                                value="CB25CO75"
                                id="cog75"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CB25CO75"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CB25CO75"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                Cog 75%,CBC 25%
                              </MenuItem>
                              <MenuItem
                                value="CB50CO50"
                                id="CBC50"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CB50CO50"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CB50CO50"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                Cog 50%,CBC 50%
                              </MenuItem>
                              <MenuItem
                                value="CB75CO25"
                                id="cog25"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CB75CO25"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CB75CO25"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                Cog 25%,CBC 75%
                              </MenuItem>
                              <MenuItem
                                value="CBC100"
                                id="cbc100"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CBC100"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CBC100"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                CBC 100%
                              </MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* </Grid> */}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  className={classes.textGrid}
                >
                  <Grid item container xs={12} sm={5} direction="column">
                    <Grid item style={{ marginTop: "0.2rem" }}>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        From CBC
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        margin="dense"
                        id="EnterAmount"
                        placeholder="From CBC"
                        disabled
                        value={amounts.cbc}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} sm={5} direction="column">
                    <Grid item>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        From COG
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        margin="dense"
                        id="EnterAmount"
                        placeholder="From COG"
                        value={amounts.cog}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.textGrid}
                  justifyContent="center"
                >
                  <Button
                    className={classes.BuyButton}
                    onClick={handleWalletModal}
                    disabled={
                      !profile?.status?.is_cogito_stocks_profile ||
                      !depositAmount ||
                      depositAmount <= 0
                    }
                  >
                    Deposit Money
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item className={classes.DepositSectionFirst}>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  className={classes.textGrid1}
                >
                  {/* <Grid item container xs={12} sm={5} > */}
                  <Grid item xs={5}>
                    <Typography style={{ textAlign: "center" }}>
                      Amount
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      value={withdrawAmount}
                      onChange={(e) => withdrawHandler(e)}
                      style={{
                        borderRadius: 10,
                        outline: "none",
                      }}
                      helperText={helperText}
                      error={!!helperText}
                      className={helperText ? classes.errorTextField : null}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography style={{ textAlign: "center" }}>
                      Wallet Currency Option
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={currencyLabels[currencyType] || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Select
                              value=""
                              onChange={handleCurrencyChange}
                              input={<BootstrapInput />}
                              IconComponent={ArrowDropDownIcon}
                              MenuProps={MenuProps}
                              id="paymentTypeDropdown"
                              classes={{ icon: classes.icon }}
                            >
                              <MenuItem
                                value="COG100"
                                id="cog100"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "COG100"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "COG100"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                COG 100%
                              </MenuItem>
                              <MenuItem
                                value="CB25CO75"
                                id="cog75"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CB25CO75"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CB25CO75"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                Cog 75%,CBC 25%
                              </MenuItem>
                              <MenuItem
                                value="CB50CO50"
                                id="CBC50"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CB50CO50"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CB50CO50"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                Cog 50%,CBC 50%
                              </MenuItem>
                              <MenuItem
                                value="CB75CO25"
                                id="cog25"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CB75CO25"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CB75CO25"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                Cog 25%,CBC 75%
                              </MenuItem>
                              <MenuItem
                                value="CBC100"
                                id="cbc100"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor:
                                    currencyType == "CBC100"
                                      ? "#1B5563"
                                      : theme.palette.background.default,
                                  color:
                                    !localStorage.getItem("dark") ||
                                    currencyType === "CBC100"
                                      ? "#fff"
                                      : "#212121",
                                }}
                              >
                                CBC 100%
                              </MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* </Grid> */}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  className={classes.textGrid}
                >
                  <Grid item container xs={12} sm={5} direction="column">
                    <Grid item style={{ marginTop: "0.2rem" }}>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        From CBC
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        margin="dense"
                        id="EnterAmount"
                        placeholder="From CBC"
                        disabled
                        value={amounts.cbc}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} sm={5} direction="column">
                    <Grid item>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        From COG
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        margin="dense"
                        id="EnterAmount"
                        placeholder="From COG"
                        value={amounts.cog}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.textGrid}
                  justifyContent="center"
                >
                  <Button
                    disabled={
                      !profile?.status?.is_cogito_stocks_profile ||
                      !withdrawAmount ||
                      withdrawAmount <= 0 ||
                      helperText?.length > 0
                    }
                    className={classes.BuyButton}
                    onClick={handleWalletModal}
                  >
                    Withdraw Money
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {walletModalOpen ? (
        <Modal
          open={walletModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "50vw",
            height: "80vh",
          }}
        >
          <Grid
            component={Paper}
            container
            xs={8}
            className={classes.modalContainer}
          >
            <Grid item container xs={12} className={classes.modalHeading}>
              <Typography style={{ paddingLeft: "20px", fontSize: "1.2rem" }}>
                {selectedTab == "0" ? "Deposit Preview" : "Withdraw Preview"}
              </Typography>
              <IconButton id="close-buy-stocks" onClick={handleCloseModal}>
                <CancelOutlined />
              </IconButton>
            </Grid>

            <>
              <Grid
                item
                container
                xs={12}
                style={{ padding: "28px", display: "flex", gap: "44px" }}
              >
                <Grid
                  item
                  xs={6}
                  direction="column"
                  style={{
                    display: "flex",
                    borderRight: "1px solid #4F4F4F",
                    gap: "20px",
                  }}
                >
                  <Typography>PIN</Typography>
                  <Typography>Confirm Your Transaction</Typography>
                  <FormControl>
                    <OTPInput
                      numInputs={6}
                      renderSeparator={<span style={{ margin: "2%" }}></span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          className={classes.otpBox}
                          autoComplete="new-password"
                        />
                      )}
                      shouldAutoFocus
                      isInputNum={true}
                      inputType="password"
                      value={security}
                      onChange={setSecurity}
                      inputStyle={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        color: localStorage.getItem("dark")
                          ? "black"
                          : "#DCDCDC",
                        fontWeight: "400",
                        backgroundColor: localStorage.getItem("dark")
                          ? "#c6c6c6"
                          : "#2e2e2e",
                      }}
                    />
                  </FormControl>
                  <Button
                    onClick={() => {
                      stockHandler();
                    }}
                    style={{ width: "30%", height: "2.5rem" }}
                    className={classes.PayButton}
                    disabled={security?.length == 6 ? false : true}
                  >
                    {depositWithdrawLoading ? <Smallspinner /> : "Confirm"}
                  </Button>
                </Grid>
                <Grid item xs={5} direction="column" container spacing={3}>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>
                      {selectedTab == "0"
                        ? "Deposit Amount"
                        : "Withdraw Amount"}
                    </Typography>
                    <Typography>
                      {selectedTab == "0" ? depositAmount : withdrawAmount}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>Currency Option</Typography>
                    <Typography>{currencyLabels[currencyType]}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>Transfer Tax 0.5%</Typography>
                    <Typography>
                      {selectedTab == "0"
                        ? depositTransferTax
                        : withdrawTransferTax}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>Transaction Total</Typography>
                    <Typography>
                      {selectedTab == "0"
                        ? totalTransactionDepositAmount
                        : totalTransactionWithdrawAmount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    walletData: userReducer.walletData,
    walletDataLoading: userReducer.loadings.walletDataLoading,
    depositWithdrawLoading: userReducer.loadings.depositWithdrawLoading,
    profile: userReducer.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    depositEngineWallet: (data) =>
      dispatch(actionCreator.depositEngineWallet(data)),
    withdrawEngineWallet: (data) =>
      dispatch(actionCreator.withdrawEngineWallet(data)),
    getEngineWallet: () => dispatch(actionCreator.getEngineWallet()),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
